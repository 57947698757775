.custom-cursor {
    width: 80px;
    height: 80px;
    background-color: #ff5733;
    border-radius: 50%;
    position: fixed;
    pointer-events: none;
    z-index: 9999;
    mix-blend-mode: difference;
    transition: all 0.3s ease-out;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  
