.first_div_element{
    width: 100%;
    height: 10px;
    background-color: white;
}

.second_div_element{
    width: 10%;
    height: 10px;
    background-image: linear-gradient(to right,#12c2e9,#c471ed,#f64f59);
    transition: width ease-in-out;
}

.second_div_element.exit {
    width: 0; 
  }