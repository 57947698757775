.snackbar {
    display: none;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 16px;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease;
  }
  
  .snackbar.show {
    display: block;
    animation: fadeIn 0.5s ease-out, fadeOut 0.5s ease-in 2.5s forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
  