#portfolio{
    padding: 50px 0;
    justify-content: center;
    margin: 50px;
}

.sub-title{
    color: white;
    text-align: center;
    font-size: 25px;
}

.container {
    background: linear-gradient(to right, #add8e6, #0000FF, #000000);
}


.work-list{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 40px;
    margin-top: 50px;
}

.work {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    }

    /* .work img {
        filter: blur(2px); /* Adjust the pixel value to control the amount of blur */
      
       
.work img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: block;
    transition: transform 0.5s;
    }

    .layer{
        width: 100%;
        height: 0;
        background: linear-gradient(rgba(0,0,0,0.6), blue);
        border-radius: 10px;
        position: absolute;
        left: 0;
        bottom: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 40px;
        text-align: center;
        font-size:14px;
        transition: height 0.5s;
        color:white;
        }
.layer h3{
    font-weight: 500;
    margin-bottom: 20px;
}

.layer a{
    margin-top: 20px;
    color: blue;
    text-decoration: none;
    font-size: 18px;
    line-height: 60px;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
}

.work:hover img{
    transform: scale(1.1);
}

.work:hover .layer{
    height: 100%;
}

.btn{
    display: block;
    margin: 50px auto;
    width: fit-content;
    border: 1px solid #ff004f;
    padding: 14px 50px;
    border-radius: 6px;
    text-decoration: none;
    color: #fff;
    transition: background 0.5s;
    }
    .btn:hover{
    background: #ff004f;
    }
    

