@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

.all{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
}

.all section{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    width: 100%;
    background: #001923;
}

.all section::before{
    content: '';
    position: absolute;
    width: 300px;
    height:300px;
    background: linear-gradient(#ffeb3b,#e91e63);
    border-radius: 50%;
    transform: translate(-420px,-180px);
}

.all section::after{
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    background: linear-gradient(#2196f3,#83d8ff);
    border-radius: 50%;
    transform: translate(400px,180px);
}

.container{
    position: relative;
    z-index: 1000;
    width: 100%;
    max-width: 1000px;
    padding: 50px;
    background-color: rgba(255,255,255,0.1);
    box-shadow: 0 25px 45px rgba(0, 0,0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-right: 1px solid rgba(255,255,255,0.1);
    border-bottom: 1px solid rgba(0, 0,0, 0.1);
    border-bottom: 10px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    border-radius: 10px;
}

.container h2{

    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 36px;
    margin-bottom: 20px;
}

.row100{

    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
}

.col{

    position: relative;
    width: 100%;
    padding: 0 10px;
    margin: 30px 0 20px;
}

.inputBox{
    position: relative;
    width: 100%;
    height: 40px;
    color: #fff;
}

.inputBox input,.inputBox textarea
{
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    box-shadow: none;
    border: none;
    outline: none;
    font-size: 15px;
    padding: 0 10px;
    z-index: 1;
    color: #000;
}

.inputBox .text{
    position: absolute;
    top: 0;
    left: 0;
    line-height: 40px;
    font-size: 18px;
    padding: 0 10px;
    display: block;
    transition: 0.5s;
    pointer-events: none;
}

.inputBox input:focus + .text,
.inputBox input:valid + .text,
.inputBox textarea:focus + .text,
.inputBox textarea:valid + .text
{
    top:-35px;
    left:-10px;
}

.inputBox .line{
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
}

.inputBox input:focus ~ .line,
.inputBox textarea:focus ~ .line{
    height: 100%;
}

.submit{
    border: none;
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    background: #fff;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    border-radius: 2px;
}

@media (max-width: 768px) {
    section::before{
        transform: translate(-200px,-180px);
    }
    section::after{
        transform: translate(220px,180px);
    }
    .container{
        padding: 20px;
    }
    .container h2{
        font-size: 28px;
    }
}


























































