*{
    text-decoration: none;
}

.resume-res{
  
    @media screen and (max-width: 600px) {
      
      width: 60px;
      text-align: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .toast{
    margin: 0;
    @media screen and (max-width: 600px) {

      top:-100;
      margin: 0;
    }
  }