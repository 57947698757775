

.hovereffect:hover{
    width: 120px;
    height: 120px;
    box-shadow: 0 0 25px green;
}

.hovereffect-vl:hover{
    width: 120px;
    height: 120px;
    box-shadow: 0 0 25px violet;
}

.hovereffect-bl:hover{
    width: 120px;
    height: 120px;
    box-shadow: 0 0 25px lightblue;
}

.hovereffect-black:hover{
    width: 120px;
    height: 120px;
    box-shadow: 0 0 25px black;
}

.hovereffect-pink:hover{
    width: 120px;
    height: 120px;
    box-shadow: 0 0 25px pink;
}